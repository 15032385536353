<template>
  <section id="hero" class="hero">
    <LazyBackgroundWrapper
      :background-path="backgroundTexture"
      background-class="hero__texture"
      :not-lazy="true"
    />
    <div class="hero__wrapper">
      <LatestNews />
      <HeroAnimation />
      <a
        v-smooth-scroll="{ offset: 1 }"
        class="button--ghost --large"
        href="#mission"
        @click="$gtag.event('cta', 'click', 'See what we do')"
      >
        <span class="button__inner"> See what we do best </span>
      </a>
      <hero-social>
        <slot />
      </hero-social>
    </div>
  </section>
</template>

<script>
import HeroAnimation from './HeroAnimation'
import LatestNews from './LatestNews'
import HeroSocial from './HeroSocial'

export default {
  name: 'Hero',
  components: {
    HeroAnimation,
    LatestNews,
    HeroSocial,
  },
  data() {
    return {
      backgroundTexture: 'paper-texture',
      socialItems: [
        {
          name: 'X',
          url: 'https://x.com/graffino',
        },
        {
          name: 'Github',
          url: 'https://github.com/Graffino',
        },
        {
          name: 'Linkedin',
          url: 'https://www.linkedin.com/company/graffino',
        },
      ],
    }
  },
}
</script>
